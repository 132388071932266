<template>
  <section class="en-m-aftersale-logistics m-scroll">
 
    <!-- 头部导航 -->
    <m-header title="Fill out the invoice">
      <span @click="onSave" slot="right" class="header-right">Save</span>
    </m-header>



    <!-- 商品信息 -->
    <div class="title">
      <span>*</span>
      <span>Waybill information</span>
    </div>
    
    <div class="info m-padding">

      <div class="info-cell">
        <span>Logistics company</span>
        <input v-model="company" type="text" >
      </div>
      <div class="info-cell">
        <span>Shipment number</span>
        <input v-model="number" type="text">
      </div>

    </div>

    <ul class="goods-list m-padding">
      <li class="goods-title">Products in the package</li>
      <li class="goods-item">
        <div class="goods-merchant">
          <div>
            <img src="@/assets/public/icon_dianpu@2x.png" alt="store">
            <span>GLH</span>
          </div>
        </div>
        <div class="item-wrap" v-for="i in 2" :key="i">
            <div class="avatar"><img src="@/assets/fork-imgs/shop04.jpg" alt="img"></div>
            <div class="info">
              <p class="van-ellipsis-l2">Xiaomi Mini 2 Smart Body Scale Balance Fat Weight Scales</p>
              <span>Color | black</span>
              <p>
                <span>$21.99</span>
                <span>x2</span>
              </p>
            </div>
          </div>
      </li>
    </ul>


    <div class="footer">
        <van-button @click="onSave"  block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Save</van-button>
      </div>  
    





  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'


export default {
  name:'AftersaleLogistics',
  components:{ MHeader },
  data(){
    return {
      company: '',
      number:  '',
    }
  },

  methods:{
    onSave(){
      const that = this 
      that.$notify({
        type: 'success',
        message: '保存成功',
        onOpened(){
          that.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.en-m-aftersale-logistics {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  background-color: #F5F9FF;
  overflow-x: hidden;
  overflow-y: auto;
  .header-right {
    color: #2A65BA;
    font-size: 12px;
    
    font-weight:600;
    color: #2A65BA;
    letter-spacing: 0.35px;
  }
  .title {
   height: 40px;
   padding: 0 16px;
   display: flex;
   align-items: center;
   font-size:14px;
   background: #fff;
   margin-top: 12px;
  
  font-weight:400;
  color:#333;
  line-height:20px;
    >span:first-of-type {
      color: #ED2A24;
    }
  }
  .goods-list {
    background: #fff;
    position: relative;
    z-index: 12;
    margin-top: 10px;
    .goods-title {
      height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #E0EDFF;
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }
    .goods-item {
      .goods-merchant {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        &>div {
          display: flex;
          align-items: center;
          &>img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          &>span {
            font-size:14px;
            font-weight:500;
            color: #333333;
            line-height:20px;
          }
        }
        &>span {
          font-size:12px;
          font-weight:600;
          color: #2A65BA;
          line-height:20px;
        }
      }
      .item-wrap {
        height: 106px;
        display: flex;
        align-items: center;
        justify-content: center;
        .avatar {
          margin:0 12px 0 8px;
          >img {
            width: 80px;
            height: 80px;
            border-radius: 5px;
          }
        }
        .info {
          flex: 1;
          height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &>p {
            font-size: 14px;
            color: #333;
            font-weight: 500;
            line-height: 20px;
            height: 40px;
            padding-right: 10px;
            width: 100%;
          }
          &>span {
            display: inline-block;
            font-size:12px;
            font-weight:400;
            color:#666;
            line-height:17px;
            margin-top: 4px;
          }
          &>p:nth-of-type(2) {
            font-size:14px;
            font-weight:500;
            color:#333;
            line-height:18px;
            margin-top: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:nth-of-type(2) {
              font-size: 16px;
              color: #000;
            }
          }
        
        }
        
      }
      
    }
  }
   .info {
    background-color: #fff;
    z-index: 12;
    .info-cell {
      height: 64px;
      display: flex;
      width: 100%;
      margin-right: 6px;
      &>span {
        width: 75px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #666;
        font-weight: 400;
      }
      &>input {
        flex: 1;
        width: 0;
        font-size: 13px;
        height: 64px;
        line-height: 26px;
        color: #666;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;
        border: none;
        appearance: none; 
        border-bottom: 1px solid #f0f0f0;
      }
      
    }
    .info-cell-line {
      margin: 8px 0 12px;
      width: 271px;
      height: 1px;
      background: #D9D9D9;
      margin-left: 75px;
    }
  }
  .footer {
    margin: 20px 38px 0;
  }
}
</style>